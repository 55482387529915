// composables/useFetch.js
import { ref } from "vue"
import { ofetch } from "ofetch"
import { useUserStore } from "~/stores/userStore"

export const useOFetch = async (
    method: string,
    url: string,
    body: any = null
): Promise<any> => {
    const config = useRuntimeConfig()

    const baseUrl = ref(config.public.MARKETPLACE_API_BASE_URL)

    const userStore = useUserStore()

    const { user } = storeToRefs(userStore)

    let defaultHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
    }

    let finalHeaders = {}

    if (user.value.access_token) {
        finalHeaders = {
            ...defaultHeaders,
            token: user.value.access_token,
        }
    } else {
        finalHeaders = {
            ...defaultHeaders,
        }
    }
    try {
        const res = await ofetch(baseUrl.value + "/" + url, {
            method: method,
            body: body,
            headers: finalHeaders,
        })
        return res
    } catch (error) {
        throw error
    }
}
