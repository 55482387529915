export const useUserStore = defineStore("data", {
    state: () => ({
        user: {
            address: "",
            email: "",
            id: 0,
            lastname: "",
            name: "",
            permissions: [""],
            phone: "",
            role: "",
            access_token: "",
        },
    }),
    getters: {
        getUser(state) {
            return state.user
        },
    },
    actions: {
        setUser(newUser: any) {
            this.user = newUser
        },
    },
})
